import React, { memo, useEffect } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { Archive } from 'components/home';
import SEO from 'components/seo';

const NewsHero = styled(BackgroundImage)`
  height: 370px;
`;

export default memo(({ data: { heroImage }})=> {
  useEffect(()=> {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <>
      <SEO title="Archiwum" />
      <NewsHero tag="div" fluid={heroImage.childImageSharp.fluid} />
      <Archive />
    </>
  );
});

export const query = graphql`{
  heroImage: file(name: {eq: "news-hero"}) {
    childImageSharp {
      fluid(maxHeight: 370, quality: 100) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }
}`;
